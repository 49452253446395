/* Base Styles */

.image-container-top {
  margin-top: -90px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.image-container-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transform: rotate(180deg);
  margin-top: 10px;
}

.language-switcher-button {
  color: rgb(111, 60, 58);
  cursor: pointer;
  margin-right: 10px;
}

.rsvp-button {
  color: rgb(111, 60, 58);
  cursor: pointer;
  margin-right: 10px;
  height: 35px;
}

.name-container {
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.home-tab-picture {
  display: block;
  width: 50%;
  margin: 0;
  margin-bottom: 30px;
  box-sizing: border-box;
  border: 8px solid rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
}

input,
select {
  margin-bottom: 16px;
  padding: 8px;
}

/* Media Queries */

/* For smaller phones (portrait) */
@media (max-width: 576px) {
  .image-container-top {
    margin-top: -60px;
  }

  .image-container-bottom {
    margin-top: 5px;
  }

  .name-container {
    margin-top: -30px;
  }

  .home-tab-picture {
    width: 90%;
    margin-bottom: 20px;
    border: 4px solid rgb(255, 255, 255);
  }

  .rsvp-button,
  .language-switcher-button {
    height: 30px;
  }
}

/* For larger phones (landscape) and small tablets */
@media (min-width: 577px) and (max-width: 768px) {
  .image-container-top {
    margin-top: -75px;
  }

  .image-container-bottom {
    margin-top: 8px;
  }

  .name-container {
    margin-top: -40px;
  }

  .home-tab-picture {
    width: 70%;
    margin-bottom: 25px;
    border: 6px solid rgb(255, 255, 255);
  }

  .rsvp-button,
  .language-switcher-button {
    height: 32px;
  }
}

/* For tablets and small desktops */
@media (min-width: 769px) and (max-width: 992px) {
  .image-container-top {
    margin-top: -80px;
  }

  .image-container-bottom {
    margin-top: 9px;
  }

  .name-container {
    margin-top: -45px;
  }

  .home-tab-picture {
    width: 60%;
    margin-bottom: 30px;
    border: 7px solid rgb(255, 255, 255);
  }

  .rsvp-button,
  .language-switcher-button {
    height: 34px;
  }
}