.envelope-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.envelope-img {
  width: 80vw;
  margin-top: 5vh;
}

.name-tag {
  font-size: 6vw;
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  text-align: center;
  /* Adjust the margin-top */
  color: rgb(111, 60, 58);
  font-family: 'MrsEavesRoman Regular';
}

.language-buttons button.selected {
  background-color: rgb(111, 60, 58);
  color: white;
  height: 3vh;
}