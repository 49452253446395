.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.image-container {
  margin: 0;
  padding: 0;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.image-container img {
  margin-top: -50px;
  margin-bottom: -400px;
  padding: 0;
  display: block;
  height: auto;
}

.language-switcher-container {
  position: absolute;
  top: -40px;
  right: 10px;
}

/* Media query for phones */
@media (max-width: 767px) {
  .image-container {
    margin-top: 10px;
    height: auto;
  }

  .image-container img {
    margin-top: -20px;
    margin-bottom: -100px;
  }

  .language-switcher-container {
    position: absolute;
    /* Position it absolutely within the parent */
    right: 0;
  }
}